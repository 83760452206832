import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useAddComplaintMutation,
	useAddOfferMutation,
	useGetQrCodeByIdQuery,
} from '../../store/api';
import { LoadingContainer, ErrorText } from './styled';
import { Container, Row, Form, Col, Button, Tab, ListGroup, Spinner, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const ComplaintForm = () => {
	const { id } = useParams();
	const [addComplaint, { isLoading, error, reset, isSuccess: complaintSuccess }] =
		useAddComplaintMutation();
	const [addOffer, { isSuccess: offerSuccess }] = useAddOfferMutation();
	const { data, isFetching } = useGetQrCodeByIdQuery(id, { refetchOnMountOrArgChange: true });
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (complaintSuccess || offerSuccess) {
			setShowModal(true);
		}
	}, [complaintSuccess, offerSuccess]);

	const {
		register: complaintRegister,
		handleSubmit: complaintHandleSubmit,
		reset: complaintReset,
		formState: { errors: complaintErrors },
	} = useForm();

	const {
		register: offerRegister,
		handleSubmit: offerHandleSubmit,
		formState: { errors: offerErrors },
	} = useForm();

	const submitComplaint = async (data) => {
		const res = await addComplaint({ ...data, choice: 'complaint', id });
		complaintReset();
	};

	const submitOffer = async (data) => {
		const res = await addOffer({ ...data, choice: 'offer', id });
	};

	const handleClose = () => {
		setShowModal(false);
	};

	if (isFetching) {
		return (
			<LoadingContainer>
				<Spinner size="lg" aria-hidden="true" />
			</LoadingContainer>
		);
	}

	if (!data?.active) {
		return <div>Sayfa Bulunamadı</div>;
	}

	return (
		<Container fluid>
			<Row className="bg-primary mb-4">
				<Col xs={12}>
					<p className="text-white text-center fw-bold mb-0 py-4">VOICEOFWORKERS</p>
				</Col>
			</Row>

			<Tab.Container id="list-group-tabs-example" defaultActiveKey="#complaint">
				<Row>
					<Col xs={12} className="mb-5">
						<ListGroup horizontal>
							<ListGroup.Item action href="#complaint" className="text-center fw-bold">
								Şikayet Bildir
							</ListGroup.Item>
							<ListGroup.Item action href="#offer" className="text-center fw-bold">
								Öneri Bildir
							</ListGroup.Item>
						</ListGroup>
					</Col>
					<Col sm={12}>
						<Tab.Content>
							<Tab.Pane eventKey="#complaint">
								<Form onSubmit={complaintHandleSubmit(submitComplaint)}>
									<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
										<Form.Label className="fw-bold fs-6">Şikayet Başlığı</Form.Label>
										<Form.Control
											{...complaintRegister('topic', {
												required: 'Şikayet başlığı zorunludur.',
												minLength: {
													value: 6,
													message: 'Şikayet başlığı en az 6 karakter olmalıdır.',
												},
											})}
											type="text"
											placeholder="Şikayet Başlığını buraya yazınız."
										/>
										<ErrorMessage
											errors={complaintErrors}
											name="topic"
											render={({ message }) => (
												<ErrorText className="my-1 text-danger">{message}</ErrorText>
											)}
										/>
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label className="fw-bold fs-6">Şikayet Konusu</Form.Label>
										<Form.Control
											as="textarea"
											rows={3}
											placeholder="Şikayet içeriğini buraya yazınız."
											{...complaintRegister('comment', {
												required: 'Şikayet içeriği zorunludur.',
												minLength: {
													value: 20,
													message: 'Şikayet içeriği en az 20 karakter olmalıdır.',
												},
											})}
										/>
										<ErrorMessage
											errors={complaintErrors}
											name="comment"
											render={({ message }) => (
												<ErrorText className="my-1 text-danger">{message}</ErrorText>
											)}
										/>
									</Form.Group>
									<Button disabled={isLoading} className="ms-auto" variant="primary" type="submit">
										Gönder
									</Button>
								</Form>
							</Tab.Pane>

							<Tab.Pane eventKey="#offer">
								<Form onSubmit={offerHandleSubmit(submitOffer)}>
									<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
										<Form.Label className="fw-bold fs-6">Öneri Başlığı</Form.Label>
										<Form.Control
											{...offerRegister('topic', {
												required: 'Başlık içeriği zorunludur.',
												minLength: {
													value: 10,
													message: 'Öneri başlığı en az 10 karakter olmalıdır.',
												},
											})}
											type="text"
											placeholder="Öneri Başlığını buraya yazınız."
										/>
										<ErrorMessage
											errors={offerErrors}
											name="topic"
											render={({ message }) => (
												<ErrorText className="my-1 text-danger">{message}</ErrorText>
											)}
										/>
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
										<Form.Label className="fw-bold fs-6">İsim</Form.Label>
										<Form.Control
											{...offerRegister('customer_name')}
											type="text"
											placeholder="Adınızı buraya yazınız."
										/>
										<ErrorMessage
											errors={offerErrors}
											name="customer_name"
											render={({ message }) => (
												<ErrorText className="my-1 text-danger">{message}</ErrorText>
											)}
										/>
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
										<Form.Label className="fw-bold fs-6">İsim</Form.Label>
										<Form.Control
											{...offerRegister('surname')}
											type="text"
											placeholder="Soyadınızı buraya yazınız."
										/>
										<ErrorMessage
											errors={offerErrors}
											name="customer_name"
											render={({ message }) => (
												<ErrorText className="my-1 text-danger">{message}</ErrorText>
											)}
										/>
									</Form.Group>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label className="fw-bold fs-6">Öneri Konusu</Form.Label>
										<Form.Control
											{...offerRegister('comment', {
												required: 'Öneri içeriği zorunludur.',
												minLength: {
													value: 20,
													message: 'Öneri içeriği en az 20 karakter olmalıdır.',
												},
											})}
											as="textarea"
											rows={3}
											placeholder="Öneri içeriğini buraya yazınız."
										/>
										<ErrorMessage
											errors={offerErrors}
											name="comment"
											render={({ message }) => (
												<ErrorText className="my-1 text-danger">{message}</ErrorText>
											)}
										/>
									</Form.Group>
									<Button className="ms-auto" variant="primary" type="submit">
										Gönder
									</Button>
								</Form>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>

			<Modal centered show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Tebrikler</Modal.Title>
				</Modal.Header>
				<Modal.Body>Mesajınız başarıyla alınmıştır.</Modal.Body>
				<Modal.Footer>
					<Button size="sm" variant="success" onClick={handleClose}>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
};

export default ComplaintForm;
