import styled from 'styled-components';

const ApplicationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: #1175d6;
	flex-direction: column;
	text-align: center;
	padding: 20px;
	color: #fff;
`;

const Box = styled.div`
	border: 1px solid #ccc;
	border-radius: 10px;
	margin-top: 25px;
	padding: 20px;
	background: #fff;
`;

const ImageContainer = styled.div`
	margin-top: 10px;
`;

const Title = styled.h4`
	font-size: 24px;
	color: #495057;
	margin-bottom: 40px;
`;

export { ApplicationContainer, Box, ImageContainer, Title };
