import { useMemo } from 'react';

const useCalculations = (data) => {
	const avarage = useMemo(() => {
		if (data) {
			const totalMount = [];

			data.forEach((item) => {
				totalMount.push(
					((item.education_expense +
						item.clothing_expense +
						item.transportation_expense +
						item.food_expense +
						item.fuel_and_water_expense +
						item.housing_expense +
						item.unexpected_expense +
						item.savings) /
						item.number_of_people_generate_income) *
						1.1,
				);
			});
			return (totalMount.reduce((a, b) => a + b) / totalMount.length).toFixed(0);
		}
		return 0;
	}, [data]);

	const energySourceStove = useMemo(() => {
		if (data) {
			const select = data.filter((item) => item.energy_source === 'Soba');
			if (select.length === 0) {
				return 0;
			}
			return (select.length / data.length) * 100;
		}
		return 0;
	}, [data]);

	const energySourceElectric = useMemo(() => {
		if (data) {
			const select = data.filter((item) => item.energy_source === 'Elektrik');
			if (select.length === 0) {
				return 0;
			}
			return (select.length / data.length) * 100;
		}
		return 0;
	}, [data]);

	const energySourceGas = useMemo(() => {
		if (data) {
			const select = data.filter((item) => item.energy_source === 'Doğalgaz');
			if (select.length === 0) {
				return 0;
			}
			return (select.length / data.length) * 100;
		}
		return 0;
	}, [data]);

	return { avarage, energySourceStove, energySourceElectric, energySourceGas };
};
export default useCalculations;
