import React from 'react';
import { Container, Spinner, Row, Col, Alert, NavLink, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetLivingWageSurveysQuery } from '../../store/api';
const Survey = () => {
	const { data, isLoading } = useGetLivingWageSurveysQuery();

	console.log(data);
	return (
		<Container>
			<Row>
				<Col xs={12} className="my-4">
					<h5 className="fw-bold">Anketler</h5>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col xs={12} className="my-3">
					<Row>
						{!data && (
							<Col xs={12}>
								<Alert variant="danger">Henüz düzenlenmiş bir anket bulunmamaktadır. </Alert>
							</Col>
						)}
						{isLoading && (
							<Spinner animation="border" role="status">
								<span className="visually-hidden">Yükleniyor...</span>
							</Spinner>
						)}
						{data &&
							data.map((item, index) => (
								<Col xs={12} key={item.id}>
									<Row>
										<Col xs={8}>{item.season} - Living wage anketi</Col>
										<Col xs={4} className="text-end">
											<NavLink to={`/survey/${item.season}`} as={Link}>
												<Button variant="outline-primary" size="sm">
													Detay
												</Button>
											</NavLink>
										</Col>
									</Row>
									<hr />
								</Col>
							))}
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default Survey;
