import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ isProtected, userRole }) => {
	const { userToken, userInfo } = useSelector((state) => state.auth);
	if (!isProtected) {
		return userToken ? <Navigate to="/" /> : <Outlet />;
	}

	if (!userToken) {
		return <Navigate to="/login" />;
	}

	if (userRole) {
		if (userRole === userInfo.role) {
			return <Outlet />;
		}
		return <Navigate to="/" />;
	}

	if (userInfo.role === 'ADMIN') {
		return <Navigate to="/admin" />;
	}

	return <Outlet />;
};

export default ProtectedRoute;
