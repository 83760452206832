import { DatePicker, Form, message, Modal } from 'antd';
import { useGetComplaintWithDateMutation } from '../../store/api';
import { useState } from 'react';

const ExportExcelModal = ({ open, onClose, choiseType, complaintStatus }) => {
	const [date, setDate] = useState();
	const [getComplaintWithDate, { data, isLoading }] = useGetComplaintWithDateMutation();
	const onOk = async () => {
		if (
			date === undefined ||
			date[0] === undefined ||
			date[0] === '' ||
			date[1] === undefined ||
			date[1] === ''
		) {
			message.error("Excel'e aktarılacak bilgilerin tarih aralığını seçiniz.");

			return;
		}

		try {
			await getComplaintWithDate({
				startDate: date[0],
				endDate: date[1],
				choiseType,
				complaintStatus,
			});
			onClose();
		} catch (error) {
			console.log(error);
		}
	};

	const handleCancel = () => {
		return onClose();
	};

	return (
		<Modal
			confirmLoading={isLoading}
			title="Excel'e Aktar"
			open={open}
			onCancel={handleCancel}
			onOk={onOk}
		>
			<DatePicker.RangePicker format="YYYY-MM-DD" onChange={(_, date) => setDate(date)} />
		</Modal>
	);
};

export default ExportExcelModal;
