import { Card, Badge } from 'antd';
import filterBadWords from '../../utils/BadWords';
import { CardMeta } from './styles';

const ComplaintCard = ({ item, onClick }) => {
	return (
		<Badge.Ribbon
			text={item.isActive ? 'Aktif' : 'Kategorisiz'}
			color={item.isActive ? 'green' : 'red'}
		>
			<Card title={filterBadWords(item.topic)} hoverable onClick={onClick}>
				<CardMeta description={filterBadWords(item.comment)} />
			</Card>
		</Badge.Ribbon>
	);
};

export default ComplaintCard;
