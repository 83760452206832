import styled from 'styled-components';

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: #f1f1f1;
	flex-direction: column;
`;

const ErrorText = styled.div`
	font-size: 12px;
`;

const DeleteButton = styled.div`
	cursor: pointer;
`;

const EditButton = styled.div`
	cursor: pointer;
`;

const ButtonsContainer = styled.div`
	display: flex;
`;

export { LoadingContainer, ErrorText, DeleteButton, EditButton, ButtonsContainer };
