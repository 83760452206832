import React from 'react';
import { ApplicationContainer, Box, ImageContainer, Title } from './styled';

const Application = () => {
	return (
		<ApplicationContainer>
			<h4>VoW Uygulama İndirme Sayfası</h4>
			<Box>
				<Title>IOS uygulamasını telefonunuza indirmek için aşağıdaki Butona tıklayın.</Title>
				<ImageContainer>
					<a href="./application/ios.app">
						<img
							style={{ width: '150px' }}
							src={`${process.env.PUBLIC_URL}/images/ios-icon.png`}
							alt="android"
						/>
					</a>
				</ImageContainer>
			</Box>
			<Box>
				<Title>ANDROID uygulamasını telefonunuza indirmek için aşağıdaki Butona tıklayın.</Title>
				<ImageContainer>
					<a href="./application/vow.apk">
						<img
							style={{ width: '150px' }}
							src={`${process.env.PUBLIC_URL}/images/android-icon.png`}
							alt="apple"
						/>
					</a>
				</ImageContainer>
			</Box>
		</ApplicationContainer>
	);
};

export default Application;
