import { Navbar, Container, Nav, Row, Col } from 'react-bootstrap';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import {
	BsHouse,
	BsSendDash,
	BsSendPlus,
	BsUiChecksGrid,
	BsMegaphone,
	BsGear,
	BsBoxArrowLeft,
} from 'react-icons/bs';
import { logout } from '../../store/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';

const AdminHome = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	return (
		<Container>
			<Row>
				<Navbar style={{ background: '#f1f1f1' }}>
					<Container>
						<Navbar.Brand className="text-white fw-bold" to="/" as={Link}>
							<img
								src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`}
								alt="voiceofworkers logo"
							/>
						</Navbar.Brand>
						<Button
							type="primary"
							icon={<LogoutOutlined />}
							iconPosition="start"
							onClick={() => dispatch(logout())}
						>
							Çıkış Yap
						</Button>
					</Container>
				</Navbar>
				<Container className="py-4 bg-primary">
					<Row>
						<Col xs={12}>
							<Nav
								className="justify-content-center"
								defaultActiveKey="/"
								activeKey={location.pathname}
							>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/"
									as={Link}
									eventKey="/"
								>
									<BsHouse /> <span className="ms-2">Şirketler</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/complaints"
									as={Link}
									eventKey="/complaints"
								>
									<BsSendDash />
									<span className="ms-2">Kullanıcılar</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/survey"
									as={Link}
									eventKey="/survey"
								>
									<BsUiChecksGrid /> <span className="ms-2">Anketler</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/settings"
									eventKey="/settings"
									as={Link}
								>
									<BsGear /> <span className="ms-2">Ayarlar</span>
								</Nav.Link>
							</Nav>
						</Col>
					</Row>
				</Container>
			</Row>
		</Container>
	);
};

const AdminLayout = () => {
	return (
		<Container fluid>
			<Row>
				<AdminHome />
				<Container>
					<Outlet />
				</Container>
			</Row>
		</Container>
	);
};

export default AdminLayout;
