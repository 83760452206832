import { createSlice } from '@reduxjs/toolkit';
import { registerUser, useUserLoginMutation, vowApi } from './api';
import { jwtDecode } from 'jwt-decode';

const token = localStorage.getItem('userToken');

const initialState = {
	loading: false,
	userInfo: token ? jwtDecode(token) : null,
	userToken: token,
	error: null,
	success: false,
	company: token ? jwtDecode(token).company : null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			console.log('logout');
			localStorage.removeItem('userToken'); // delete token from storage
			state.loading = false;
			state.userInfo = null;
			state.userToken = null;
			state.error = null;
			state.company = null;
		},
		setCredentials: (state, { payload }) => {
			state.userInfo = payload;
		},
	},
	extraReducers: (builder) => {
		//login user
		builder.addMatcher(vowApi.endpoints.userLogin.matchFulfilled, (state, { payload }) => {
			//state.loading = false;
			state.userInfo = jwtDecode(payload.token);
			state.userToken = payload.token;
			state.company = jwtDecode(payload.token).company;
			localStorage.setItem('userToken', payload.token);
		});
		builder.addMatcher(vowApi.endpoints.adminLogin.matchFulfilled, (state, { payload }) => {
			//state.loading = false;
			state.userInfo = jwtDecode(payload.token);
			state.userToken = payload.token;
			state.company = jwtDecode(payload.token).company;
			localStorage.setItem('userToken', payload.token);
		});
		// register user
		// builder.addCase(registerUser.pending, (state) => {
		//   state.loading = true;
		//   state.error = null;
		// });
		// builder.addCase(registerUser.fulfilled, (state, { payload }) => {
		//   state.loading = false;
		//   state.success = true; // registration successful
		// });
		// builder.addCase(registerUser.rejected, (state, { payload }) => {
		//   state.loading = false;
		//   state.error = payload;
		// });
	},
});

export const { logout, setCredentials } = authSlice.actions;

export default authSlice.reducer;
