import styled from 'styled-components';

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: #f1f1f1;
	flex-direction: column;
`;

const ErrorText = styled.div`
	font-size: 12px;
`;

const DeleteButton = styled.div`
	cursor: pointer;
`;

const EditButton = styled.div`
	cursor: pointer;
`;

const ButtonsContainer = styled.div`
	display: flex;
`;

const FileUploadButton = styled.input`
	&[type='file']::-webkit-file-upload-button {
		opacity: 0;
		width: 16px;
	}
	&::before {
		content: 'Lütfen dosya seçiniz';
		display: inline-block;
		background: linear-gradient(top, #f9f9f9, #e3e3e3);
		border: 1px solid #1677ff;
		border-radius: 5px;
		padding: 5px 8px;
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		cursor: pointer;
		text-shadow: 1px 1px #fff;
		font-weight: 700;
		font-size: 10pt;
		color: #1677ff;
	}
	&:hover::before {
		border-color: #1677ff;
	}
	&:active::before {
		background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
	}
`;

export {
	LoadingContainer,
	ErrorText,
	DeleteButton,
	EditButton,
	ButtonsContainer,
	FileUploadButton,
};
