import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TranslateEN from './locales/en/global.json';
import TranslateTR from './locales/tr/global.json';

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	debug: true,
	resources: {
		en: {
			translation: TranslateEN,
		},
		tr: {
			translation: TranslateTR,
		},
	},
});

export default i18n;
