import React, { useState } from 'react';
import { useGetComplaintQuery, useGetOffersQuery } from '../../store/api';
import { Card, Row, Col, Alert, Container, Spinner } from 'react-bootstrap';
import { Button } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';

const ComplaintCard = ({ title, item, showMore, getAction }) => {
	return (
		<Card>
			<Card.Header>{title} Başlığı</Card.Header>
			<Card.Body>
				<Card.Title></Card.Title>
				<Card.Subtitle className="mb-2 text-muted">{item.topic}</Card.Subtitle>
				<Card.Text>
					<p className="fs-6 fw-bold mb-0">İçerik:</p> {item.comment}
				</Card.Text>
				<Card.Text>
					<p className="fs-6 fw-bold mb-0">Cevap:</p>
					{item.updateComplaint}
				</Card.Text>
			</Card.Body>
			<Card.Footer className="d-flex justify-content-between">
				<Button variant="outline-success" size="sm" className="me-3" onClick={showMore}>
					Detay Gör
				</Button>
			</Card.Footer>
		</Card>
	);
};

const ReportPage = () => {
	const [openExportExcel, setOpenExportExcel] = useState(false);
	const { data: complaint, isLoading: complaintLoading } = useGetComplaintQuery();
	const { data: offer, isLoading: offerLoading } = useGetOffersQuery();
	const [modalData, setModalData] = useState();

	return (
		<Container>
			<Row>
				<Col xs={12} className="my-4">
					<h5 className="fw-bold">Aksiyon Alınan Dilek - Şikayetler</h5>
					<hr />
					<div>
						<Button onClick={() => setOpenExportExcel(true)}>Excel'e Aktar</Button>
					</div>
				</Col>
			</Row>
			<Row>
				{complaint?.complaint
					?.filter((item) => item.complaintStatus === 1)
					.map((item, index) => {
						return (
							<Col key={item.id} xs={12} sm={12} md={6} lg={4} xl={3} className="mb-5">
								<ComplaintCard
									title="Şikayet"
									item={item}
									showMore={() => setModalData({ ...item, action: false })}
									getAction={() => setModalData({ ...item, action: true })}
								/>
							</Col>
						);
					})}

				{offer?.offer
					?.filter((item) => item.complaintStatus === 1)
					.map((item, index) => {
						return (
							<Col key={item.id} xs={12} sm={12} md={6} lg={4} xl={3} className="mb-5">
								<ComplaintCard
									title="Öneri"
									item={item}
									showMore={() => setModalData({ ...item, action: false })}
									getAction={() => setModalData({ ...item, action: true })}
								/>
							</Col>
						);
					})}
			</Row>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={1}
			/>
		</Container>
	);
};

export default ReportPage;
