import React, { useState } from 'react';
import { Form, Row, Col, Button, Modal, Container, ListGroup, Alert, Badge } from 'react-bootstrap';
import { useAddWorkerMutation } from '../../store/api';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from './styled';

const AddWorkerPopup = ({ item, isShow, handleClose }) => {
	const [addWorker, { data, isLoading }] = useAddWorkerMutation();

	const {
		register: announceRegister,
		handleSubmit: announceHandleSubmit,
		reset: updateFormReset,
		formState: { errors: announceErrors },
	} = useForm();

	const updateAnnouncement = async (data) => {
		await addWorker(data);
		updateFormReset();
		handleClose();
	};

	const closeButton = () => {
		handleClose();
	};

	return (
		<>
			<Modal show={isShow} centered onHide={handleClose}>
				<Modal.Header closeButton>
					<h6>Çalışan Telefon Numarası Ekle</h6>
				</Modal.Header>
				<Form onSubmit={announceHandleSubmit(updateAnnouncement)}>
					<Modal.Body>
						{data && data?.message && (
							<Alert size="sm" variant="info">
								{data?.message}
							</Alert>
						)}
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Control
								{...announceRegister('phone_number', {
									required: 'Telefon numarası zorunludur.',
									minLength: {
										value: 10,
										message: 'Telefon numarası en az 10 karakter olmalıdır.',
									},
									maxLength: 13,
									pattern:
										/^(\+90|0)?\s*(\(\d{3}\)[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}|\(\d{3}\)[\s-]*\d{3}[\s-]*\d{4}|\(\d{3}\)[\s-]*\d{7}|\d{3}[\s-]*\d{3}[\s-]*\d{4}|\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2})$/,
								})}
								type="text"
								placeholder="+905554443322"
							/>
							<ErrorMessage
								errors={announceErrors}
								name="phone_number"
								render={({ message }) => (
									<ErrorText className="my-1 text-danger">{message}</ErrorText>
								)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" size="sm" onClick={closeButton}>
							Kapat
						</Button>
						<Button
							size="sm"
							disabled={isLoading}
							className="ms-auto"
							variant="success"
							type="submit"
						>
							Ekle
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default AddWorkerPopup;
