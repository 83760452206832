import { logout } from './authSlice';

const AuthMiddleware = (store) => (next) => (action) => {
	if (action.payload) {
		//console.log('action.payload', action.payload);
		if (action.payload.status === 401) {
			store.dispatch(logout());
		}
	}
	return next(action);
};

export default AuthMiddleware;
