import styled from 'styled-components';

const LoginContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f1f1f1;
	flex-direction: column;
`;

const Label = styled.div`
	font-size: 1rem;
	margin-bottom: 15px;
`;

const Logo = styled.div`
	margin: 0 0 50px;
	img {
		width: 200px;
	}
`;

const FormContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`;

const LeftContainer = styled.div`
	text-align: center;
	color: #000;
`;

const Desc = styled.p`
	margin-top: 20px;
`;

const Button = styled.button`
	background: #0076de;
	min-width: 100px;
	padding: 12px 16px;
	color: white;
	cursor: pointer;
	border-radius: 5px;
	text-align: center;
	width: 100%;
	border: none;
`;

const InputWrapper = styled.div`
	margin-bottom: 15px;
	input {
		width: 300px;
		height: 40px;
		padding: 5px 10px;
		border: none;
		border-radius: 5px;
		color: #444;
		&:focus {
			border: none;
			outline: none;
		}
	}

	&:last-child {
		margin-bottom: 25px;
	}
`;

const Alert = styled.div`
	border: 1px solid #f1b4b4;
	background: #fff;
	padding: 10px 16px;
	border-radius: 3px;
	margin: 15px 0 0;
`;

const Error = styled.div`
	color: red;
	margin-top: 10px;
	font-size: 0.8rem;
	color: #e00000;
`;

export {
	LoginContainer,
	Label,
	Logo,
	Button,
	InputWrapper,
	Alert,
	Error,
	FormContainer,
	LeftContainer,
	Desc,
};
