import { useState } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useAddUserMutation, useGetCompanyUsersQuery } from '../../../store/api';
import { Col, Row, FloatButton, Table, Modal, Space, Input, Form, Button, Badge } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

const CompanyDetail = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { companyId } = useParams();
	const { data } = useGetCompanyUsersQuery(companyId);
	const [addUser, { isSuccess, isError }] = useAddUserMutation();
	const [form] = Form.useForm();
	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onFinish = async (values) => {
		const val = { ...values, company_id: companyId };
		console.log('Success:', isSuccess);
		const res = await addUser(val);
		if (isSuccess) {
			setIsModalOpen(false);
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const columns = [
		{
			title: 'Kullanıcı ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Kullanıcı E-posta',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Kullanıcı Adı',
			dataIndex: 'name',
			key: 'name',
		},
	];

	return (
		<>
			<FloatButton
				icon={<PlusCircleFilled />}
				type="primary"
				style={{ insetInlineEnd: 14 }}
				onClick={showModal}
			/>
			<Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
				<Col span={24} className="gutter-row">
					<Table tableLayout="auto" dataSource={data?.user || []} columns={columns} rowKey={'id'} />
				</Col>
			</Row>
			<Modal title="Yeni Kullanıc Ekle" open={isModalOpen} onCancel={handleCancel}>
				<Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
					<Form
						name="basic"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item
							name="user_name"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı e-postagiriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıcı e-posta adresi" name="user_name" />
						</Form.Item>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı adını giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıc Adı" name="name" />
						</Form.Item>
						<Form.Item
							name="user_type"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı tipini giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıcı Tipi" name="email" />
						</Form.Item>
						<Form.Item
							wrapperCol={{
								offset: 8,
								span: 16,
							}}
						>
							<Button type="primary" htmlType="submit" width={100}>
								Kaydet
							</Button>
						</Form.Item>
					</Form>
				</Space>
			</Modal>
		</>
	);
};

export default CompanyDetail;
