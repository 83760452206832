import React from 'react';
import { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Calculate from '../../components/calculate';
import { DevTool } from '@hookform/devtools';
import { useGetQrCodeByIdQuery, useSendFormMutation } from '../../store/api';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { LoadingContainer } from './styled';

function LivingWageSurvey() {
	const [sendForm, { isLoading, isSuccess }] = useSendFormMutation();
	const [showModal, setShowModal] = useState(false);
	const [isSended, setIsSended] = useState(localStorage.getItem('check-form'));
	const { id } = useParams();
	const { data, isFetching } = useGetQrCodeByIdQuery(id, { refetchOnMountOrArgChange: true });

	useEffect(() => {
		if (isSuccess) {
			setShowModal(true);
		}
	}, [isSuccess]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
	} = useForm({
		defaultValues: {
			currency: 'TL',
			// number_of_people_generate_income: 3,
			// number_of_children: 2,
			// adult_in_eduction: 2,
			// energy_source: 'Soba',
			// transport_vehicle: 'Otobüs',
			// distance_home_to_work: 2,
			// distance_shop_to_home: 2,
			// distance_school_to_home: 2,
			// distance_hospital_to_home: 2,
			// access_to_water: false,
			// education_expense: 10,
			// transportation_expense: 100,
			// food_expense: 200,
			// clothing_expense: 200,
			// fuel_and_water_expense: 500,
			// housing_expense: 300,
			// unexpected_expense: 400,
			// savings: 200,
		},
	});
	const submit = (data) => {
		const returnedTarget = Object.assign(data, {
			id: id,
			season: '2023',
		});
		sendForm(returnedTarget);
		localStorage.setItem('check-form', true);
	};

	const handleClose = () => {
		setShowModal(false);
		setIsSended(true);
	};

	if (isFetching) {
		return (
			<LoadingContainer>
				<Spinner size="lg" aria-hidden="true" />
			</LoadingContainer>
		);
	}

	if (!data?.active) {
		return <div>Sayfa bulunamadı</div>;
	}

	if (isSended) {
		return (
			<Container>
				<Row className="bg-primary mb-4">
					<Col xs={12}>
						<p className="text-white text-center fw-bold mb-0 py-4">VOICEOFWORKERS</p>
					</Col>
				</Row>
				<p className="text-center">Bu Ankete Daha önce katıldınız, bu sayfayı kapatabilirsiniz.</p>
			</Container>
		);
	}

	return (
		<div className="App">
			<Container>
				<Row className="bg-primary mb-4">
					<Col xs={12}>
						<p className="text-white text-center fw-bold mb-0 py-4">VOICEOFWORKERS</p>
					</Col>
				</Row>

				<Row>
					<Col lg={10} className="mb-4">
						<h5 className="text-center">Adil Yaşam Ücreti Anketi</h5>
					</Col>
					<Col lg={10} className="mb-4">
						<Form onSubmit={handleSubmit(submit)} noValidate>
							<Row>
								<Col lg={10} xs={12}>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Yerel Para Birimi
										</Form.Label>
										<Col sm={12} md={5}>
											<Form.Control
												type="text"
												size="sm"
												disabled
												className={errors.currency ? 'border-danger' : ''}
												{...register('currency', { required: true })}
											/>
											<div errors={errors} name="currency">
												{errors.currency?.message}
											</div>
										</Col>
									</Form.Group>

									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Haneye gelir getiren kişi sayısı
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.number_of_people_generate_income ? 'border-danger' : ''}
												{...register('number_of_people_generate_income', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text>Kişi</InputGroup.Text>
										</Col>
									</Form.Group>

									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Hanedeki çocuk sayısı
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.number_of_children ? 'border-danger' : ''}
												{...register('number_of_children', { required: true, valueAsNumber: true })}
											/>
											<InputGroup.Text>Kişi</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Bir ailede işten sonra eğitim kursuna katılan ortalama yetişkin sayısı:
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.adult_in_eduction ? 'border-danger' : ''}
												{...register('adult_in_eduction', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text>Kişi</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Handeki ısınma için kullanılan enerji kaynağı:
										</Form.Label>
										<Col sm={12} md={5}>
											<Form.Select
												{...register('energy_source', { required: true })}
												className={errors.energy_source ? 'border-danger' : ''}
											>
												<option value="">Seçiniz</option>
												<option value="Doğalgaz">Doğalgaz</option>
												<option value="Elektrik">Elektrik</option>
												<option value="Soba">Soba</option>
											</Form.Select>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Ulaşım için hangi araçlar kullanılıyor
										</Form.Label>
										<Col sm={12} md={5}>
											<Form.Select
												{...register('transport_vehicle', { required: true })}
												className={errors.transport_vehicle ? 'border-danger' : ''}
											>
												<option value="">Seçiniz</option>
												<option value="Otobüs">Otobüs</option>
												<option value="Tren">Tren</option>
												<option value="Dolmuş">Dolmuş</option>
												<option value="Servis">Servis</option>
												<option value="Özel Araç">Özel Araç</option>
											</Form.Select>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Ev ile iş arası kaç km
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.distance_home_to_work ? 'border-danger' : ''}
												{...register('distance_home_to_work', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text id="basic-addon2">KM</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											En yakın alışveriş merkezine uzaklık ne kadar?
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.distance_shop_to_home ? 'border-danger' : ''}
												{...register('distance_shop_to_home', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text id="basic-addon2">KM</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											En yakın okul ne kadar uzaklıkta km
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.distance_school_to_home ? 'border-danger' : ''}
												{...register('distance_school_to_home', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text id="basic-addon2">KM</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											En yakın sağlık kuruluşu ne kadar uzaklıkta km
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.distance_hospital_to_home ? 'border-danger' : ''}
												{...register('distance_hospital_to_home', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text id="basic-addon2">KM</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Evde içme suyuna erişim var mı?
										</Form.Label>
										<Col sm={12} md={5}>
											<Form.Select
												{...register('access_to_water', { required: true })}
												className={errors.access_to_water ? 'border-danger' : ''}
											>
												<option value="">Seçiniz</option>
												<option value="1">Evet</option>
												<option value="0">Hayır</option>
											</Form.Select>
										</Col>
									</Form.Group>
								</Col>
								<Col lg={10} className="my-4">
									<h5 className="mb-4 text-center">Ortalama Aile gider bilgileri</h5>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Eğitim Masrafı
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.education_expense ? 'border-danger' : ''}
												{...register('education_expense', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Ulaşım Masrafı
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.transportation_expense ? 'border-danger' : ''}
												{...register('transportation_expense', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Mutfak Masrafı
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.food_expense ? 'border-danger' : ''}
												{...register('food_expense', { required: true, valueAsNumber: true })}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Giyim Masrafı
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.clothing_expense ? 'border-danger' : ''}
												{...register('clothing_expense', { required: true, valueAsNumber: true })}
											/>
											<InputGroup.Text id="basic-addon2">TL</InputGroup.Text>
										</Col>
									</Form.Group>
								</Col>
								<Col lg={10} className="mb-4">
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Yakıt ve Su giderleri
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.fuel_and_water_expense ? 'border-danger' : ''}
												{...register('fuel_and_water_expense', {
													required: true,
													valueAsNumber: true,
												})}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Ev giderleri
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												className={errors.housing_expense ? 'border-danger' : ''}
												{...register('housing_expense', { required: true, valueAsNumber: true })}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Beklenmeyen giderler
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												{...register('unexpected_expense', { required: true, valueAsNumber: true })}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
									<Form.Group as={Row} className="mb-3">
										<Form.Label column className="text-md-end">
											Birikim
										</Form.Label>
										<Col className="d-flex" sm={12} md={5}>
											<Form.Control
												type="number"
												size="sm"
												{...register('savings', { required: true, valueAsNumber: true })}
											/>
											<InputGroup.Text>TL</InputGroup.Text>
										</Col>
									</Form.Group>
								</Col>

								<Col className="d-grid gap-2 mb-4" lg={12}>
									<Button type="submit" variant="success" size="lg" disabled={isLoading}>
										{isLoading ? (
											<Spinner animation="border" variant="light" size="sm" />
										) : (
											'Kaydet'
										)}
									</Button>
								</Col>
								{/* <Calculate /> */}
							</Row>
						</Form>
					</Col>
				</Row>
			</Container>
			<DevTool control={control} />
			<Modal centered show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Tebrikler</Modal.Title>
				</Modal.Header>
				<Modal.Body>Anketi başarıyla tamamladınız.</Modal.Body>
				<Modal.Footer>
					<Button size="sm" variant="success" onClick={handleClose}>
						Kapat
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default LivingWageSurvey;
