import React, { useState } from 'react';
import { Row, Col, Alert, Container, Spinner } from 'react-bootstrap';
import { useGetOffersQuery } from '../../store/api';
import CustomModal from '../../components/Modals';
import ComplaintCard from '../../components/ComplaintCard';
import { Button } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';

const Offer = () => {
	const { data: offer, isLoading } = useGetOffersQuery();
	const [modalData, setModalData] = useState();
	const [openExportExcel, setOpenExportExcel] = useState(false);
	return (
		<Container>
			<Row>
				<Col xs={12} className="my-4">
					<h5 className="fw-bold">Öneriler</h5>
					<hr />
					<div>
						<Button onClick={() => setOpenExportExcel(true)}>Excel'e Aktar</Button>
					</div>
				</Col>
			</Row>
			<Row>
				{offer?.message && (
					<Col xs={12}>
						<Alert variant="danger">Şu an öneri bulunmamaktadır. </Alert>
					</Col>
				)}
				{isLoading && (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Yükleniyor...</span>
					</Spinner>
				)}
				{offer?.offer?.map((item, index) => {
					return (
						<Col key={item.id} xs={12} sm={12} md={6} lg={4} xl={3} className="mb-5">
							<ComplaintCard item={item} onClick={() => setModalData({ ...item, action: true })} />
						</Col>
					);
				})}
				<CustomModal
					data={modalData}
					isShow={modalData !== undefined}
					handleClose={() => setModalData(undefined)}
				/>
			</Row>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={0}
				choiseType="offer"
			/>
		</Container>
	);
};

export default Offer;
