import React, { useState } from 'react';
import { useGetWorkersListQuery, useUpdateWorkerMutation } from '../../store/api';
import { Form, Col, ListGroup, Badge } from 'react-bootstrap';
import { ButtonsContainer, EditButton } from './styled';
import { FiEdit } from 'react-icons/fi';
import { Button, Row, Modal, Upload, message } from 'antd';
import AddWorkerPopup from './addWorkersSingle';
import UploadWithExcel from './uploadWithExcel';

const WorkersList = () => {
	const { data, refetch } = useGetWorkersListQuery();
	const [updateWorker, { isLoading }] = useUpdateWorkerMutation();
	const [modalData, setModalData] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const addWorker = () => {
		setModalData(true);
		refetch();
	};

	const handleChange = (isActive, id) => {
		console.log('handlechange', isActive, id);
		const status = isActive === 1 ? 0 : 1;
		const data = { isActive: status, id: id };
		updateWorker(data);
		refetch();
	};

	return (
		<Row gutter={8} style={{ marginTop: 20, marginBottom: 20 }} justify="center">
			<Col span={24} xs={24} xl={10}>
				<div className="d-flex justify-content-between">
					<h5 className="fw-bold">Çalışan Listesi</h5>

					<div>
						<Button onClick={addWorker} size="sm" variant="danger" className="me-2">
							Hızlı Çalışan Ekle
						</Button>
						<Button type="primary" onClick={showModal}>
							Excel ile Çalışan Aktar
						</Button>
					</div>
				</div>
				<hr />
			</Col>
			<Col xs={12} xl={10}>
				<ListGroup as="ul" className="mb-5">
					<ListGroup.Item className="d-flex justify-content-between">
						<div className="fw-bold">Telefon Numarası</div>
						<div>
							<span className="me-4 fw-bold">Durum</span>
							<span className="me-4 fw-bold">Düzenle</span>
							<span className="fw-bold">Güncelle</span>
						</div>
					</ListGroup.Item>
					{data &&
						data.workersList?.map((item, index) => {
							return (
								<ListGroup.Item
									as="li"
									key={index}
									className=" d-flex justify-content-between align-items-end w-100"
								>
									<div>{item.phone_number}</div>
									<ButtonsContainer>
										<div className="me-5 text-start">
											<Badge bg={item.isActive === 1 ? 'success' : 'danger'}>
												{item.isActive === 1 ? 'Aktif' : 'Pasif'}
											</Badge>
										</div>
										<EditButton className="me-4" onClick={console.log('test')}>
											<FiEdit color="green" />
										</EditButton>
										{/* <DeleteButton onClick={() => handleChange(item.isActive, item.id)}>
												<BsFillTrash3Fill color="red" />
											</DeleteButton> */}
										<Form.Check
											type="switch"
											id="custom-switch"
											name="ratingCheckbox"
											checked={item.isActive || false}
											onChange={() => handleChange(item.isActive, item.id)}
											label=""
										/>
									</ButtonsContainer>
								</ListGroup.Item>
							);
						})}
				</ListGroup>
			</Col>
			<AddWorkerPopup
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<UploadWithExcel isShow={isModalOpen} onClose={closeModal} />
		</Row>
	);
};

export default WorkersList;
