import { useParams } from 'react-router-dom';
import { Container, Row, Col, Alert, Spinner, Table, Button, Card } from 'react-bootstrap';
import { useGetLivingWageSurveyResultQuery } from '../../store/api';
import { CSVLink } from 'react-csv';
import { LoadingContainer, Info } from './style';
import useCalculations from '../../hook/calculations';
import { GiWoodPile } from 'react-icons/gi';
import { GiElectric } from 'react-icons/gi';
import { SiFireship } from 'react-icons/si';

const headers = [
	{ label: 'Haneye gelir getiren kişi sayısı', key: 'number_of_people_generate_income' },
	{ label: 'Hanedeki çocuk sayısı', key: 'number_of_children' },
	{ label: 'Eğitim Alan yetişkin Sayısı', key: 'adult_in_eduction' },
	{ label: 'Isınma Tipi', key: 'energy_source' },
	{ label: 'Ulaşım', key: 'transport_vehicle' },
	{ label: 'Ev-İş Arası Mesafe', key: 'distance_home_to_work' },
	{ label: 'En yakın AVM mesafesi', key: 'distance_shop_to_home' },
	{ label: 'En yakın Okul', key: 'distance_school_to_home' },
	{ label: 'En yakın sağlık kuruluşu', key: 'distance_hospital_to_home' },
	{ label: 'İçme suyu erişimi', key: 'access_to_water' },
	{ label: 'Eğitim Masrafı', key: 'education_expense' },
	{ label: 'Ulaşım Masrafı', key: 'transportation_expense' },
	{ label: 'Gıda Masrafı', key: 'food_expense' },
	{ label: 'Giyim Masrafı', key: 'clothing_expense' },
	{ label: 'Enerji ve Su giderleri', key: 'fuel_and_water_expense' },
	{ label: 'Barınma Giderleri', key: 'housing_expense' },
	{ label: 'Beklenmeyen Gider', key: 'unexpected_expense' },
	{ label: 'Birikim', key: 'savings' },
];

const SurveyDetail = () => {
	const { season } = useParams();
	const { data, isError, isLoading } = useGetLivingWageSurveyResultQuery(season);

	const { avarage, energySourceElectric, energySourceStove, energySourceGas } =
		useCalculations(data);

	if (isLoading) {
		return (
			<LoadingContainer>
				<Spinner size="lg" aria-hidden="true" />
			</LoadingContainer>
		);
	}

	if (isError) {
		return (
			<Container>
				<Row>
					<Col xs={12} className="mt-4">
						<Alert variant="danger">{season} yılına ait ankette veri bulunamadı.</Alert>
					</Col>
				</Row>
			</Container>
		);
	}

	return (
		<Container>
			<Row className="my-4">
				<Col xs={9}>
					<h5 className="fw-bold">{season} Anket Sonuçları</h5>
				</Col>
				<Col xs={3} className="text-end mb-3">
					{!isLoading && (
						<Button size="sm" as={CSVLink} data={data} separator={';'} headers={headers}>
							Excele Aktar
						</Button>
					)}
				</Col>
				<hr />
			</Row>
			<Row>
				<Col xs={12} md={4}>
					<Card className="bg-info bg-gradient text-white">
						<Card.Body className="d-flex flex-row justify-content-around">
							<Card.Title className="fw-bold fs-6">
								<div className="fw-bold fs-6">
									Soba ile ısınan <br /> Çalışanların Yüzdesi
								</div>
								<div className="me-4 fs-1 fw-bold">% {energySourceStove} </div>
							</Card.Title>
							<Card.Text className="fs-1">
								<GiWoodPile />
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={4}>
					<Card className="bg-info bg-gradient text-white">
						<Card.Body className="d-flex flex-row justify-content-around">
							<Card.Title>
								<div className="fw-bold fs-6">
									Doğalgaz ile ısınan <br /> Çalışanların Yüzdesi
								</div>
								<div className="me-4 fs-1 fw-bold">% {energySourceGas} </div>
							</Card.Title>
							<Card.Text className="fs-1">
								<SiFireship />
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} md={4}>
					<Card className="bg-info bg-gradient text-white">
						<Card.Body className="d-flex flex-row justify-content-around">
							<Card.Title>
								<div className="fw-bold fs-6">
									Elektrik ile ısınan <br /> Çalışanların Yüzdesi
								</div>
								<div className="me-4 fs-1 fw-bold">% {energySourceElectric} </div>
							</Card.Title>
							<Card.Text style={{ fontSize: '4rem' }}>
								<GiElectric />
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col xs={12} className="mt-4">
					<Alert variant="primary">Ortalama : {avarage} TL</Alert>
				</Col>
				<Col xs={12}>
					<div style={{ overflowX: 'scroll' }}>
						<Table responsive="lg">
							<thead>
								<tr>
									<th>Haneye gelir getiren kişi sayısı</th>
									<th>Hanedeki çocuk sayısı</th>
									<th>Eğitim Alan yetişkin Sayısı</th>
									<th>Isınma Tipi</th>
									<th>Ulaşım</th>
									<th>Ev-İş Arası Mesafe</th>
									<th>En yakın AVM mesafesi</th>
									<th>En yakın Okul</th>
									<th>En yakın sağlık kuruluşu</th>
									<th>İçme suyu erişimi</th>
									<th>Eğitim Masrafı</th>
									<th>Ulaşım Masrafı</th>
									<th>Gıda Masrafı</th>
									<th>Giyim Masrafı</th>
									<th>Enerji ve Su giderleri</th>
									<th>Barınma Giderleri</th>
									<th>Beklenmeyen Gider</th>
									<th>Birikim</th>
								</tr>
							</thead>
							<tbody>
								{data &&
									data?.map((item, index) => {
										return (
											<tr key={item.index}>
												<td>
													{item.number_of_people_generate_income} <Info>Kişi</Info>
												</td>
												<td>
													{item.number_of_children} <Info>Kişi</Info>
												</td>
												<td>
													{item.adult_in_eduction} <Info>Kişi</Info>
												</td>
												<td>{item.energy_source}</td>
												<td>{item.transport_vehicle}</td>
												<td>
													{item.distance_home_to_work} <Info>Km</Info>
												</td>
												<td>
													{item.distance_shop_to_home} <Info>Km</Info>
												</td>
												<td>
													{item.distance_school_to_home} <Info>Km</Info>
												</td>
												<td>
													{item.distance_hospital_to_home} <Info>Km</Info>
												</td>
												<td>{item.access_to_water === 1 ? 'Evet' : 'Hayır'}</td>
												<td>
													{item.education_expense} <Info>TL</Info>
												</td>
												<td>
													{item.transportation_expense} <Info>TL</Info>
												</td>
												<td>
													{item.food_expense} <Info>TL</Info>
												</td>
												<td>
													{item.clothing_expense} <Info>TL</Info>
												</td>
												<td>
													{item.fuel_and_water_expense} <Info>TL</Info>
												</td>
												<td>
													{item.housing_expense} <Info>TL</Info>
												</td>
												<td>
													{item.unexpected_expense} <Info>TL</Info>
												</td>
												<td>
													{item.savings} <Info>TL</Info>
												</td>
											</tr>
										);
									})}
							</tbody>
						</Table>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default SurveyDetail;
