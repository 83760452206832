import { useState } from 'react';
// import { Form } from 'react-bootstrap';
import { useSetUpdateCompalaintMutation } from '../../store/api';
import { Select, Modal, Form, Input, Space } from 'antd';

const CustomModal = ({ isShow, handleClose, data }) => {
	const [value, setValue] = useState('');
	const [category, setCategory] = useState();
	const [updateComplaint, { isLoading, error }] = useSetUpdateCompalaintMutation();
	const [form] = Form.useForm();
	const submit = async (values) => {
		console.log('form', values);
		const res = await updateComplaint({
			...values,
			complaintStatus: true,
			id: data.id,
		});
		handleClose();
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const ops = [
		{
			value: '1',
			label: 'Kategori Yok',
		},
		{
			value: '2',
			label: 'Ücret Beklentisi',
		},
		{
			value: '3',
			label: 'Mobbing',
		},
		{
			value: '4',
			label: 'Temizlik',
		},
		{
			value: '5',
			label: 'Sağlık',
		},
		{
			value: '6',
			label: 'Taciz',
		},
		{
			value: '7',
			label: 'Hakaret',
		},
	];
	return (
		<Modal
			title={data?.topic}
			open={isShow}
			okButtonProps={{
				htmlType: 'submit',
				form: 'custom-modal-form',
			}}
			onCancel={handleClose}
		>
			<Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
				<p>{data?.comment}</p>
				{data?.action && (
					<>
						<hr></hr>
						<Form
							id="custom-modal-form"
							name="basic"
							layout="vertical"
							initialValues={{
								remember: true,
							}}
							onFinish={submit}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
							form={form}
						>
							<Form.Item>
								Bu {data?.choise === 'offer' ? 'Öneriyi' : 'Şikayeti'} kapatmak için alınan aksiyonu
								yazabilirsiniz.
							</Form.Item>
							<Form.Item
								label="Kategori"
								name="category"
								rules={[
									{
										required: true,
										message: 'Lütfen bu mesaj için bir kategori seçiniz.',
									},
								]}
							>
								<Select
									name="category"
									showSearch={false}
									placeholder="Lütfen Seçiniz"
									optionFilterProp="label"
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={ops}
								/>
							</Form.Item>

							<Form.Item
								label="Mesaj"
								name="updateComplaint"
								rules={[
									{
										required: true,
										message: 'Lütfen bu mesaj için yorum giriniz!',
									},
								]}
							>
								<Input.TextArea name="updateComplaint" onChange={(e) => setValue(e.target.value)} />
							</Form.Item>
						</Form>
					</>
				)}
			</Space>
		</Modal>
		// <Modal show={isShow} centered onHide={handleClose}>
		// 	<Modal.Header closeButton>
		// 		<Modal.Title>{data?.topic}</Modal.Title>
		// 	</Modal.Header>
		// 	<Modal.Body>
		// 		{data?.comment}

		// 		{data?.action && (
		// 			<>
		// 				<hr></hr>
		// 				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
		// 					<Form.Label>
		// 						Bu {data?.choise === 'offer' ? 'Öneriyi' : 'Şikayeti'} kapatmak için alınan aksiyonu
		// 						yazabilirsiniz.
		// 					</Form.Label>
		// 					<div>
		// 						<Select
		// 							showSearch
		// 							style={{ width: 200 }}
		// 							placeholder="Search to Select"
		// 							optionFilterProp="label"
		// 							filterSort={(optionA, optionB) =>
		// 								(optionA?.label ?? '')
		// 									.toLowerCase()
		// 									.localeCompare((optionB?.label ?? '').toLowerCase())
		// 							}
		// 							options={[
		// 								{
		// 									value: '1',
		// 									label: 'Not Identified',
		// 								},
		// 								{
		// 									value: '2',
		// 									label: 'Closed',
		// 								},
		// 								{
		// 									value: '3',
		// 									label: 'Communicated',
		// 								},
		// 								{
		// 									value: '4',
		// 									label: 'Identified',
		// 								},
		// 								{
		// 									value: '5',
		// 									label: 'Resolved',
		// 								},
		// 								{
		// 									value: '6',
		// 									label: 'Cancelled',
		// 								},
		// 							]}
		// 						/>
		// 					</div>
		// 					<Form.Control onChange={(e) => setValue(e.target.value)} as="textarea" rows={3} />
		// 				</Form.Group>
		// 			</>
		// 		)}
		// 	</Modal.Body>
		// 	<Modal.Footer>
		// 		<Button variant="secondary" size="sm" onClick={handleClose}>
		// 			Kapat
		// 		</Button>
		// 		{data?.action && (
		// 			<Button variant="primary" size="sm" disabled={value.length < 20} onClick={submit}>
		// 				Kaydet
		// 			</Button>
		// 		)}
		// 	</Modal.Footer>
		// </Modal>
	);
};

export default CustomModal;
