import React, { useState } from 'react';
import { Spinner, Alert, ListGroup, Modal } from 'react-bootstrap';
import { Button, Form, Input, Radio, Tag, Row, Col } from 'antd';
import {
	useGetAnnouncesQuery,
	useSetAddAnnounceMutation,
	useUpdateaAnnounceMutation,
} from '../../store/api';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { DeleteButton, ErrorText, EditButton, ButtonsContainer } from './styled';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { FiEdit } from 'react-icons/fi';

const AnnounceCard = ({ item }) => {
	const [update, { isLoading }] = useUpdateaAnnounceMutation();
	const [show, setShow] = useState(false);
	const updateAnnounce = (item) => {
		console.log('update', item);
		setShow(true);
	};

	const {
		register: announceRegister,
		handleSubmit: announceHandleSubmit,
		reset: updateFormReset,
		formState: { errors: announceErrors },
	} = useForm();

	const updateAnnouncement = async (data) => {
		await update(data);
		updateFormReset();
	};

	const handleClose = () => setShow(false);
	return (
		<>
			<ListGroup.Item as="li">
				<div className="ms-2 d-flex justify-content-between align-items-end w-100">
					<div>
						<div className="fw-bold">{item.topic}</div>
						{item.comment}
					</div>
					<ButtonsContainer>
						<DeleteButton>
							<BsFillTrash3Fill color="red" />
						</DeleteButton>
						<EditButton onClick={() => updateAnnounce(item)}>
							<FiEdit color="green" className="ms-2" />
						</EditButton>
					</ButtonsContainer>
				</div>
			</ListGroup.Item>
			<Modal show={show} centered onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Duyuru Düzenle</Modal.Title>
				</Modal.Header>
				<Form onSubmit={announceHandleSubmit(updateAnnouncement)}>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label className="fw-bold fs-6">Duyuru Başlığı</Form.Label>
							<Form.Control
								{...announceRegister('topic', {
									required: 'Duyuru başlığı zorunludur.',
									minLength: {
										value: 6,
										message: 'Duyuru başlığı en az 6 karakter olmalıdır.',
									},
								})}
								type="text"
								placeholder={item.topic}
							/>
							<ErrorMessage
								errors={announceErrors}
								name="topic"
								render={({ message }) => (
									<ErrorText className="my-1 text-danger">{message}</ErrorText>
								)}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
							<Form.Label className="fw-bold fs-6">Duyuru Konusu</Form.Label>
							<Form.Control
								as="textarea"
								rows={3}
								placeholder={item.comment}
								{...announceRegister('comment', {
									required: 'Duyuru içeriği zorunludur.',
									minLength: {
										value: 20,
										message: 'Duyuru içeriği en az 20 karakter olmalıdır.',
									},
								})}
							/>
							<ErrorMessage
								errors={announceErrors}
								name="comment"
								render={({ message }) => (
									<ErrorText className="my-1 text-danger">{message}</ErrorText>
								)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" size="sm" onClick={handleClose}>
							Kapat
						</Button>
						<Button
							size="sm"
							disabled={isLoading}
							className="ms-auto"
							variant="success"
							type="submit"
						>
							Güncelle
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

const Announce = () => {
	const { data: annnounce, isLoading, refetch } = useGetAnnouncesQuery();
	const [addAnnounce, { isLoading: addAnnounceLoading, errors }] = useSetAddAnnounceMutation();

	const {
		register: announceRegister,
		handleSubmit: announceHandleSubmit,
		reset: announceFormReset,
		formState: { errors: announceErrors },
	} = useForm();
	const [form] = Form.useForm();

	const submitAnnouncement = async (data) => {
		await addAnnounce(data);
		announceFormReset();
		refetch();
	};

	const onFinish = async (values) => {
		console.log('Success:', values);
		await addAnnounce(values);
		announceFormReset();
		refetch();
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
				<Col span={24}>
					<Form
						name="basic"
						layout="vertical"
						initialValues={{
							remember: false,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item
							label="Duyuru Başlığı"
							name="topic"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket Adı Giriniz!',
								},
							]}
						>
							<Input name="topic" placeholder="Duyuru başlığı" />
						</Form.Item>
						<Form.Item
							label="Duyuru İçeriği"
							name="comment"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket Adı Giriniz!',
								},
							]}
						>
							<Input.TextArea name="comment" placeholder="Duyuru İçeriği" />
						</Form.Item>
						<Form.Item>
							<Button type="primary">Gönder</Button>
						</Form.Item>
					</Form>
					{/* <Form onSubmit={announceHandleSubmit(submitAnnouncement)}>
							<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
								<Form.Label className="fw-bold fs-6">Duyuru Başlığı</Form.Label>
								<Form.Control
									{...announceRegister('topic', {
										required: 'Duyuru başlığı zorunludur.',
										minLength: {
											value: 6,
											message: 'Duyuru başlığı en az 6 karakter olmalıdır.',
										},
									})}
									type="text"
									placeholder="Duyuru Başlığını buraya yazınız."
								/>
								<ErrorMessage
									errors={announceErrors}
									name="topic"
									render={({ message }) => (
										<ErrorText className="my-1 text-danger">{message}</ErrorText>
									)}
								/>
							</Form.Group>
							<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
								<Form.Label className="fw-bold fs-6">Duyuru Konusu</Form.Label>
								<Form.Control
									as="textarea"
									rows={3}
									placeholder="Duyuru içeriğini buraya yazınız."
									{...announceRegister('comment', {
										required: 'Duyuru içeriği zorunludur.',
										minLength: {
											value: 20,
											message: 'Duyuru içeriği en az 20 karakter olmalıdır.',
										},
									})}
								/>
								<ErrorMessage
									errors={announceErrors}
									name="comment"
									render={({ message }) => (
										<ErrorText className="my-1 text-danger">{message}</ErrorText>
									)}
								/>
							</Form.Group>
							<Button
								disabled={addAnnounceLoading}
								className="ms-auto"
								variant="primary"
								type="submit"
							>
								Gönder
							</Button>
						</Form> */}
				</Col>
			</Row>
			<Row>
				<Col xs={12} className="my-4">
					<h5 className="fw-bold">Duyurular</h5>
					<hr />
				</Col>
			</Row>
			<Row>
				{isLoading && (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Yükleniyor...</span>
					</Spinner>
				)}
				{annnounce?.message && (
					<Col xs={12}>
						<Alert variant="danger">Şu an duyuru bulunmamaktadır. </Alert>
					</Col>
				)}
				<ListGroup as="ul" className="mb-5">
					{annnounce?.announcment?.map((item, index) => {
						return <AnnounceCard item={item} />;
					})}
				</ListGroup>
			</Row>
			<hr />
		</>
	);
};

export default Announce;
