import React, { useState } from 'react';
import { Modal, message } from 'antd';
import * as XLSX from 'xlsx';
import { FileUploadButton } from './styled';

const UploadWithExcel = ({ isShow, onClose }) => {
	const [data, setData] = useState(null);
	const handleOk = () => {
		onClose();
	};
	const handleCancel = () => {
		onClose();
	};

	const handleFileUpload = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();

		reader.onload = (event) => {
			console.log(event.target.result);
			const workbook = XLSX.read(event.target.result, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const sheetData = XLSX.utils.sheet_to_json(sheet);
			setData(sheetData);
		};
		reader.readAsBinaryString(file);
	};

	return (
		<Modal title="Kullanıcıları içe aktar" open={isShow} onOk={handleOk} onCancel={handleCancel}>
			<p>Excelde oluşturduğunuz kullanıcıları buradan topluca ekleyebilirsiniz.</p>
			<FileUploadButton type="file" onChange={handleFileUpload} />
		</Modal>
	);
};

export default UploadWithExcel;
