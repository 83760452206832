import React from 'react';
import {
	Label,
	LoginContainer,
	Button,
	InputWrapper,
	Error,
	Alert,
	FormContainer,
	LeftContainer,
	Desc,
} from './styled';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { useAdminLoginMutation } from '../../../store/api';

const AdminPage = () => {
	const [loginUser, { isLoading, error, reset, isSuccess }] = useAdminLoginMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const submitForm = async (data) => {
		const res = await loginUser(data);
		console.log('error', error, 'isLoading', isLoading, 'isSuccess', isSuccess);
	};

	return (
		<LoginContainer>
			<div className="h-100  w-50">
				<FormContainer>
					<form onSubmit={handleSubmit(submitForm)} className="mt-5">
						<InputWrapper>
							<input
								type="text"
								placeholder="Kullancı adı"
								onFocus={reset}
								{...register('user_name', { required: 'Email Adresi Zorunludur.' })}
							/>
						</InputWrapper>
						<InputWrapper>
							<input
								type="password"
								placeholder="Şifre"
								onFocus={reset}
								{...register('user_password', {
									required: 'Şifre Zorunludur.',
									minLength: {
										value: 6,
										message: 'Şifreniz en az 6 karakter olmalıdır.',
									},
								})}
							/>
						</InputWrapper>
						<Button type="submit" disabled={isLoading}>
							{isLoading && (
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									className="me-4"
								/>
							)}
							Giriş Yap
						</Button>
					</form>
				</FormContainer>
			</div>
		</LoginContainer>
	);
};

export default AdminPage;
