import React from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useUserLoginMutation } from '../../store/api';
import Spinner from 'react-bootstrap/Spinner';
import {
	Label,
	LoginContainer,
	Button,
	InputWrapper,
	Error,
	Alert,
	FormContainer,
	LeftContainer,
	Desc,
} from './styled';
import { ErrorMessage } from '@hookform/error-message';
import { BsFillKeyFill, BsEnvelope, BsBoxArrowInRight } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

// import Logo from '../../components/Logo';

const LoginScreen = () => {
	const { t, i18n } = useTranslation();
	const [loginUser, { isLoading, error, reset }] = useUserLoginMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const submitForm = async (data) => {
		const res = await loginUser(data);
	};

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};

	return (
		<LoginContainer>
			{/* <Logo color="#343a40" size="2rem" /> */}
			<LeftContainer className="h-100 w-50">
				<div className="p-3 text-end">
					<button className="btn btn-sm" variant="link" onClick={() => changeLanguage('tr')}>
						TR
					</button>
					|
					<button className="btn btn-sm" variant="link" onClick={() => changeLanguage('en')}>
						EN
					</button>
				</div>
				<div className="p-5">
					<img src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`} alt="voiceofworkers logo" />
					<Desc className="text-dark"> {t('login.desc')}</Desc>
				</div>
			</LeftContainer>

			<div className="h-100  w-50">
				<FormContainer>
					<form onSubmit={handleSubmit(submitForm)} className="mt-5">
						<InputWrapper>
							<Label>
								<BsEnvelope /> {t('login.user_name')}
							</Label>
							<input
								type="text"
								onFocus={reset}
								{...register('user_name', { required: 'Email Adresi Zorunludur.' })}
							/>
							<ErrorMessage
								errors={errors}
								name="user_name"
								render={({ message }) => <Error>{message}</Error>}
							/>
						</InputWrapper>
						<InputWrapper>
							<Label>
								<BsFillKeyFill /> {t('login.user_password')}
							</Label>
							<input
								type="password"
								onFocus={reset}
								{...register('user_password', {
									required: 'Şifre Zorunludur.',
									minLength: {
										value: 6,
										message: 'Şifreniz en az 6 karakter olmalıdır.',
									},
								})}
							/>
							<ErrorMessage
								errors={errors}
								name="user_password"
								render={({ message }) => <Error>{message}</Error>}
							/>
						</InputWrapper>
						<Button type="submit" disabled={isLoading}>
							{isLoading && (
								<Spinner
									as="span"
									animation="border"
									size="sm"
									role="status"
									aria-hidden="true"
									className="me-4"
								/>
							)}
							<BsBoxArrowInRight /> {isLoading ? t('login.login_redirect') : t('login.login_btn')}
						</Button>
					</form>
					{/* {error && (
						<Alert key="danger" variant="danger">
							{error?.data.message}
						</Alert>
					)} */}
					<div className="mt-4">
						<NavLink className="text-decoration-none color-dark link-dark" to="/forgot-password">
							{t('login.forgot_password')}
						</NavLink>
					</div>
				</FormContainer>
			</div>
		</LoginContainer>
	);
};

export default LoginScreen;
