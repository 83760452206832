import { Navbar, Container, Nav, Row, Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { logout } from '../../store/authSlice';
import {
	BsHouse,
	BsSendDash,
	BsSendPlus,
	BsUiChecksGrid,
	BsMegaphone,
	BsGear,
	BsBoxArrowLeft,
} from 'react-icons/bs';

const Header = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.auth);

	return (
		<Container className="bg-dark">
			<Row>
				<Navbar style={{ background: '#f1f1f1' }}>
					<Container>
						<Navbar.Brand className="text-white fw-bold" to="/" as={Link}>
							<img
								src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`}
								alt="voiceofworkers logo"
							/>
						</Navbar.Brand>
						<Button size="sm" onClick={() => dispatch(logout())}>
							<BsBoxArrowLeft /> Çıkış Yap
						</Button>
					</Container>
				</Navbar>
				<Container className="py-4 bg-primary">
					<Row>
						<Col xs={12}>
							<Nav
								className="justify-content-center"
								defaultActiveKey="/"
								activeKey={location.pathname}
							>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/"
									as={Link}
									eventKey="/"
								>
									<BsHouse /> <span className="ms-2">Anasayfa</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/complaints"
									as={Link}
									eventKey="/complaints"
								>
									<BsSendDash />
									<span className="ms-2">Şikayetler</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/offers"
									as={Link}
									eventKey="/offers"
								>
									<BsSendPlus /> <span className="ms-2">Öneriler</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/reports"
									as={Link}
									eventKey="/reports"
								>
									<BsSendPlus /> <span className="ms-2">Aksiyon Alınanlar</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/survey"
									as={Link}
									eventKey="/survey"
								>
									<BsUiChecksGrid /> <span className="ms-2">Anketler</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/announce"
									as={Link}
									eventKey="/announce"
								>
									<BsMegaphone />
									<span className="ms-2">Duyurular</span>
								</Nav.Link>
								{/* <Nav.Link to="/application" as={Link}>
									Uygulamalar
								</Nav.Link> */}
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/workers-list"
									as={Link}
									eventKey="/workers-list"
								>
									<BsMegaphone />
									<span className="ms-2">Çalışan Listesi</span>
								</Nav.Link>
								<Nav.Link
									className="text-white d-flex align-items-center"
									to="/settings"
									eventKey="/settings"
									as={Link}
								>
									<BsGear /> <span className="ms-2">Ayarlar</span>
								</Nav.Link>
							</Nav>
						</Col>
					</Row>
				</Container>
			</Row>
		</Container>
	);
};

const Layout = () => {
	return (
		<Container fluid>
			<Row>
				<Header />
				<Container>
					<Outlet />
				</Container>
			</Row>
		</Container>
	);
};

export default Layout;
