import React, { useState } from 'react';
import { Row, Col, Alert, Container, Spinner } from 'react-bootstrap';
import CustomModal from '../../components/Modals';
import { useGetComplaintQuery } from '../../store/api';
import ComplaintCard from '../../components/ComplaintCard';
import { Button } from 'antd';
import ExportExcelModal from '../../components/ExportExcelModal';

const Complaint = () => {
	const { data: complaint, isLoading } = useGetComplaintQuery();
	const [modalData, setModalData] = useState();
	const [openExportExcel, setOpenExportExcel] = useState(false);

	return (
		<Container>
			<Row>
				<Col xs={12} className="my-4">
					<h5 className="fw-bold">Şikayetler</h5>
					<hr />
					<div>
						<Button onClick={() => setOpenExportExcel(true)}>Excel'e Aktar</Button>
					</div>
				</Col>
			</Row>
			<Row>
				{complaint?.message && (
					<Col xs={12}>
						<Alert variant="danger">Şu an şikayet bulunmamaktadır. </Alert>
					</Col>
				)}
				{isLoading && (
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Yükleniyor...</span>
					</Spinner>
				)}
				{complaint?.complaint
					?.filter((val) => val.complaintStatus === 0)
					.map((item, index) => {
						console.log('item', item.complaintStatus);
						return (
							<Col key={item.id} xs={12} sm={12} md={6} lg={4} xl={3} className="mb-5">
								<ComplaintCard
									item={item}
									onClick={() => setModalData({ ...item, action: true })}
								/>
							</Col>
						);
					})}
			</Row>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={0}
				choiseType="complaint"
			/>
		</Container>
	);
};

export default Complaint;
