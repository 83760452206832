import { useState } from 'react';
import { Col, Row, Card, FloatButton, Modal, Space, Input, Form, Button, Badge } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useGetAllCompanyQuery, useSetCompanyMutation } from '../../../store/api';

const AdminHome = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useDispatch();
	const { data } = useGetAllCompanyQuery();
	const [addCompany, { isSuccess, isError }] = useSetCompanyMutation();
	let navigate = useNavigate();
	const [form] = Form.useForm();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onFinish = async (values) => {
		console.log('Success:', values);
		const res = await addCompany(values);
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<FloatButton
				icon={<PlusCircleFilled />}
				type="primary"
				style={{ insetInlineEnd: 14 }}
				onClick={showModal}
			/>
			<Row gutter={16} style={{ marginTop: 20, marginBottom: 20 }}>
				{data?.company.map((item, index) => {
					return (
						<Col span={8} key={index} styles={{ marginBottom: 20 }}>
							<Badge.Ribbon
								text={item.isActive ? 'Aktif' : 'Pasif'}
								color={item.isActive ? 'green' : 'red'}
							>
								<Card
									style={{ marginTop: 16 }}
									title={item.company_name}
									bordered={false}
									hoverable
									onClick={() => navigate(`/company-detail/${item.id}`, { replace: true })}
								>
									<Card.Meta title={item.company_administrator} description={item.company_email} />
									<Card.Meta
										style={{ marginTop: 20 }}
										title={item.company_phone}
										description={item.create_datetime}
									/>
								</Card>
							</Badge.Ribbon>
						</Col>
					);
				})}
			</Row>
			<Modal
				title="Yeni Şirket Ekle"
				open={isModalOpen}
				okButtonProps={{
					htmlType: 'submit',
					form: 'create-company-modal-form',
				}}
				onCancel={handleCancel}
			>
				<Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
					<Form
						id="create-company-modal-form"
						name="basic"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item
							name="company_name"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket Adı Giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Adı" name="company_name" />
						</Form.Item>
						<Form.Item
							name="company_admin"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket Yetkilisinin Adını Giriniz!',
								},
							]}
						>
							<Input placeholder="Yetkili Kişi" name="company_admin" />
						</Form.Item>
						<Form.Item
							name="email"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket eposta adresini giriniz!',
								},
							]}
						>
							<Input placeholder="Yetkili E-posta Adresi" name="email" />
						</Form.Item>
						<Form.Item
							name="phone"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket telefon numarasını giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Telefonu" name="phone" />
						</Form.Item>
						<Form.Item
							name="address"
							rules={[
								{
									required: true,
									message: 'Lütfen Şirket adresini giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Adresi" name="address" />
						</Form.Item>
						<Form.Item
							name="tax_admin"
							rules={[
								{
									required: false,
									message: 'Lütfen Şirket Vergi Dairesini giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Vergi Dairesi" name="tax_admin" />
						</Form.Item>
						<Form.Item
							name="tax_no"
							rules={[
								{
									required: false,
									message: 'Lütfen Şirket adresini giriniz!',
								},
							]}
						>
							<Input placeholder="Şirket Vergi Numarası" name="tax_no" />
						</Form.Item>
					</Form>
				</Space>
			</Modal>
		</>
	);
};
export default AdminHome;
