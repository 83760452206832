import { configureStore } from '@reduxjs/toolkit';
import { vowApi } from './api';
import authReducer from './authSlice';
import AuthMiddleware from './authMiddleware';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		[vowApi.reducerPath]: vowApi.reducer,
	},
	middleware: (getDefaultMiddleWare) =>
		getDefaultMiddleWare().concat(AuthMiddleware).concat(vowApi.middleware),
});

export default store;
