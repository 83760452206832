import { Card } from 'antd';
import styled from 'styled-components';

const CardMeta = styled(Card.Meta)`
	& .ant-card-meta-description {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
`;

export { CardMeta };
