import { Container, Row, Col, Card, Button, Navbar, Spinner } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useCreateQrCodeMutation, useGetQrCodeQuery } from '../../store/api';
import { useMemo } from 'react';

const Settings = () => {
	const { company } = useSelector((state) => state.auth);
	const { data: qrData, refetch, isFetching } = useGetQrCodeQuery();
	const [createQrCode, { data, isLoading }] = useCreateQrCodeMutation();

	const onClickCreateQrCode = () => {
		createQrCode();
	};

	const loading = useMemo(() => isFetching || isLoading, [isFetching, isLoading]);
	const qrId = useMemo(() => data?.qrId || qrData?.qrId, [data, qrData]);
	return (
		<Container>
			<Col xs={12} className="my-3">
				<Navbar className="bg-body-tertiary">
					<Container>
						<Navbar.Brand>Ayarlar</Navbar.Brand>
					</Container>
				</Navbar>
			</Col>
			<Row>
				<Col xl={12}>
					<Card>
						<Card.Header>Şikayet karekodu</Card.Header>
						<Card.Body>
							<Card.Title>Şikayet için oluşturulan kare kod</Card.Title>
							<Card.Body>
								{qrId ? (
									<Row>
										<Col lg={4}>
											<div
												style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}
											>
												<QRCode
													size={256}
													style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
													value={`${process.env.REACT_APP_QR_LINK}complaint/${qrId}`}
													viewBox={`0 0 256 256`}
												/>
											</div>
										</Col>
										<Col lg={8}>Link : {`${process.env.REACT_APP_QR_LINK}complaint/${qrId}`}</Col>
									</Row>
								) : (
									'QR Code henüz oluşturulmadı'
								)}
							</Card.Body>
							<Button variant="primary" onClick={onClickCreateQrCode} disabled={loading}>
								{loading && (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className="me-4"
									/>
								)}
								Oluştur
							</Button>
						</Card.Body>
					</Card>
				</Col>

				<Col xl={12} className="mt-5">
					<Card>
						<Card.Header>Yaşam Boyu Sürdürülebilirlik anketi karekodu</Card.Header>
						<Card.Body>
							<Card.Title>Yaşam Boyu Sürdürülebilirlik anketi kare kod</Card.Title>
							<Card.Body>
								{qrId ? (
									<Row>
										<Col lg={4}>
											<div
												style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}
											>
												<QRCode
													size={256}
													style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
													value={`${process.env.REACT_APP_QR_LINK}living-wage-form/${qrId}`}
													viewBox={`0 0 256 256`}
												/>
											</div>
										</Col>
										<Col lg={8}>
											Link : {`${process.env.REACT_APP_QR_LINK}living-wage-form/${qrId}`}
										</Col>
									</Row>
								) : (
									'QR Code henüz oluşturulmadı'
								)}
							</Card.Body>
							<Button variant="primary" onClick={onClickCreateQrCode} disabled={loading}>
								{loading && (
									<Spinner
										as="span"
										animation="border"
										size="sm"
										role="status"
										aria-hidden="true"
										className="me-4"
									/>
								)}
								Oluştur
							</Button>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Settings;
