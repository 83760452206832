import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button, NavLink } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useGetComplaintQuery } from '../../store/api';
import { useGetOffersQuery } from '../../store/api';
import { useSelector } from 'react-redux';

const Home = () => {
	const { data: complaint } = useGetComplaintQuery();
	const { data: offer } = useGetOffersQuery();
	const { userInfo } = useSelector((state) => state.auth);

	return (
		<Container>
			<Row>
				<Col xs={12} className="my-4">
					<h5 className="fw-bold">Anasayfa</h5>
					<hr />
				</Col>
			</Row>

			<Row>
				<Col xs={12} lg={4}>
					<Card className="text-center">
						<Card.Body>
							<Card.Title>
								{complaint?.complaint.length > 0
									? 'Bekleyen şikayetleriniz var! '
									: 'Harika Hiç Bekleyen Şikayetiniz Yok.'}
								<br /> <br />
							</Card.Title>
							<Card.Text>
								Zamanında aksiyon almak için şikayet sayfasına gidip şikayetleri
								düzenleyebilirsiniz.
							</Card.Text>
							<NavLink to={`/complaints`} as={Link}>
								<Button size="sm" variant="outline-danger">
									Şikayet Sayfasına Git
								</Button>
							</NavLink>
						</Card.Body>
						<Card.Footer className="text-muted">
							Bekleyen şikayet sayısı: {complaint?.complaint.length}
						</Card.Footer>
					</Card>
				</Col>
				<Col xs={12} lg={4}>
					<Card className="text-center">
						<Card.Body>
							<Card.Title>
								{offer?.offer.length > 0
									? 'Çalışanlarınızdan gelen önerilieriniz var!'
									: 'Çalışanlardan Öneriler Duyabilsek Harika Olmaz mı?'}
							</Card.Title>
							<Card.Text>
								Önerileri gözden geçirip iyiliştirme alanları belirleyebilirsiniz.
							</Card.Text>
							<NavLink to={`/offers`} as={Link}>
								<Button size="sm" variant="outline-success">
									Öneri Sayfasına Git
								</Button>
							</NavLink>
						</Card.Body>
						<Card.Footer className="text-muted">
							Bekleyen öneri sayısı: {offer?.offer.length}
						</Card.Footer>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default Home;
