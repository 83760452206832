import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

//const baseURl = 'http://localhost:3008/';
const baseURl = 'https://vow-backend.onrender.com/';

export const vowApi = createApi({
	reducerPath: 'vowApi',
	baseQuery: fetchBaseQuery({
		baseUrl: baseURl,
		prepareHeaders: (headers, { getState }) => {
			const { userToken } = getState().auth;
			if (userToken) {
				headers.set('authorization', `Bearer ${userToken}`);
			}

			return headers;
		},
	}),
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	endpoints: (builder) => ({
		getResult: builder.query({
			query: (name) => `living-wage-result`,
		}),
		sendForm: builder.mutation({
			query: ({ id, ...body }) => ({
				url: `living-wage/${id}`,
				method: 'POST',
				body: body,
			}),
		}),
		userLogin: builder.mutation({
			query: (data) => ({
				url: `login`,
				method: 'POST',
				body: data,
			}),
		}),
		addComplaint: builder.mutation({
			query: ({ id, ...data }) => ({
				url: `add-complaint/${id}`,
				method: 'POST',
				body: data,
			}),
		}),
		addOffer: builder.mutation({
			query: ({ id, ...data }) => ({
				url: `add-offer/${id}`,
				method: 'POST',
				body: data,
			}),
		}),
		getComplaint: builder.query({
			query: () => `getAllComplaint`,
		}),
		getOffers: builder.query({
			query: () => `getAllOffers`,
		}),
		getAnnounces: builder.query({
			query: () => `get-all-announcment`,
		}),
		getLivingWageSurveys: builder.query({
			query: () => `getAll-living-wage`,
		}),
		getLivingWageSurveyResult: builder.query({
			query: (season) => `get-living-wage-result/${season}`,
		}),
		getQrCode: builder.query({
			query: () => `getQrCode`,
		}),
		createQrCode: builder.mutation({
			query: () => ({ url: `createQrCode`, method: 'POST' }),
		}),
		getQrCodeById: builder.query({
			query: (id) => `/getQrCode/${id}`,
		}),
		setUpdateCompalaint: builder.mutation({
			query: (data) => ({
				url: `update-complaint`,
				method: 'POST',
				body: data,
			}),
		}),
		setAddAnnounce: builder.mutation({
			query: (data) => ({
				url: `add-announcement`,
				method: 'POST',
				body: data,
			}),
		}),
		updateaAnnounce: builder.mutation({
			query: (data) => ({
				url: `update-announcement`,
				method: 'POST',
				body: data,
			}),
		}),
		getWorkersList: builder.query({
			query: () => `getWorkersList`,
		}),
		addWorker: builder.mutation({
			query: (data) => ({
				url: `addWorkers`,
				method: 'POST',
				body: data,
			}),
		}),
		updateWorker: builder.mutation({
			query: (data) => ({
				url: `updateWorker`,
				method: 'POST',
				body: data,
			}),
		}),
		adminLogin: builder.mutation({
			query: (data) => ({
				url: `/admin-login`,
				method: 'POST',
				body: data,
			}),
		}),
		getAllCompany: builder.query({
			query: () => `admin/get-all-company`,
		}),
		getCompanyUsers: builder.query({
			query: (company_id) => `/admin/get-company-users/${company_id}`,
		}),
		setCompany: builder.mutation({
			query: (data) => ({
				url: `/company`,
				method: 'POST',
				body: data,
			}),
		}),
		addUser: builder.mutation({
			query: (data) => ({
				url: `/register`,
				method: 'POST',
				body: data,
			}),
		}),
		getComplaintWithDate: builder.mutation({
			query: (data) => ({
				url: `get-complaints-with-date`,
				method: 'POST',
				body: data,
				responseHandler: async (response) =>
					window.location.assign(window.URL.createObjectURL(await response.blob())),
			}),
		}),
		createPassword: builder.mutation({
			query: (data) => ({
				url: `create-password`,
				method: 'POST',
				body: data,
			}),
		}),
	}),
});

export const {
	useSendFormMutation,
	useUserLoginMutation,
	useGetComplaintQuery,
	useGetOffersQuery,
	useGetAnnouncesQuery,
	useGetLivingWageSurveysQuery,
	useGetLivingWageSurveyResultQuery,
	useGetQrCodeByIdQuery,
	useGetQrCodeQuery,
	useCreateQrCodeMutation,
	useAddComplaintMutation,
	useAddOfferMutation,
	useSetUpdateCompalaintMutation,
	useSetAddAnnounceMutation,
	useUpdateaAnnounceMutation,
	useGetWorkersListQuery,
	useAddWorkerMutation,
	useUpdateWorkerMutation,
	useAdminLoginMutation,
	useGetAllCompanyQuery,
	useGetCompanyUsersQuery,
	useSetCompanyMutation,
	useAddUserMutation,
	useGetComplaintWithDateMutation,
	useCreatePasswordMutation,
} = vowApi;
