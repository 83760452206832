import Filter from 'bad-words';

const badWords = [
	'ibne',
	'got',
	'yavsak',
	'am',
	'amcik',
	'orospu cocugu',
	'pezevenk',
	'pezenvenk',
	'yarrak',
	'yarak',
	'pust',
	'amcik',
	'amına koyım',
	'puşt',
	'gey',
	'kahpe',
	'kaltak',
	'orospu',
	'azina sicarim',
	'koca got',
	'pic',
	'gotlek',
	'pic kurusu',
	'yrrak',
	'yrrk',
	'gt',
	'pezenvenk',
];
const filter = new Filter({ list: badWords, replaceRegex: /[A-Za-z0-9]/g });

const compareWords = (a, b) => {
	if (b.replaceAll('*', '') === '') {
		return b;
	}
	return a;
};
const filterBadWords = (val) => {
	const topic = val
		.replace(/ı/g, 'i')
		.replace(/ç/g, 'c')
		.replace(/ş/g, 's')
		.replace(/ö/g, 'o')
		.replace(/ü/g, 'u')
		.replace(/ğ/g, 'g')
		.replace(/İ/g, 'I')
		.replace(/Ç/g, 'C')
		.replace(/Ş/g, 'S')
		.replace(/Ö/g, 'O')
		.replace(/Ü/g, 'U')
		.replace(/Ğ/g, 'G');

	const filteredVal = filter.clean(topic || '');

	if (topic === filteredVal) {
		return val;
	}

	const filteredVals = val.split(' ');

	return filteredVal
		.split(' ')
		.map((val, index) => {
			return compareWords(filteredVals[index], val);
		})
		.join(' ');
};

export default filterBadWords;
