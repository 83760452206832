import React, { useEffect } from 'react';
import { Col, Row, Spin, Input, Form, Button, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { useCreatePasswordMutation } from '../../store/api';

const CreatePassword = () => {
	const [percent, setPercent] = React.useState(0);
	const [form, watch] = Form.useForm();
	const { pass_token } = useParams();
	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();
	const [sendPassword, { data, isSuccess, isError, isLoading }] = useCreatePasswordMutation();
	useEffect(() => {
		form.setFieldValue('token', pass_token);
	}, [pass_token]);

	const onFinish = async (values) => {
		const res = await sendPassword(values);
		if (res.data && !res.data.error) {
			setPercent(100);
			console.log('success');
			messageApi.open({
				type: 'success',
				content: 'Şifreniz başarılı bir şekilde oluşturulmuştur.',
			});
			form.resetFields();
			navigate(`/`);
		} else {
			messageApi.open({
				type: 'error',
				content: 'Şifre oluşturma başarısız.',
			});
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<Spin spinning={isLoading} percent={percent} fullscreen />
			{contextHolder}
			<Row gutter={16} justify="center" align="middle" style={{ marginTop: 20, marginBottom: 20 }}>
				<Col xs={18} sm={12} md={8} lg={8} xl={6} className="gutter-row" justify="center">
					<div style={{ marginBottom: 50, textAlign: 'center' }}>
						<img src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`} alt="voiceofworkers logo" />
					</div>

					<h4 style={{ textAlign: 'center', marginBottom: 40, marginTop: 40 }}>
						Yeni Şifre Oluştur
					</h4>
					<p style={{ textAlign: 'center' }}>
						Aşağıdaki şifre alanlarına şifrenizi belirleyebilirsiniz. Şifreniz en az 8 karakter
						uzunluğunda harf, rakam ve özel karakterlerden oluşmalıdır.{' '}
					</p>
					<Form
						name="basic"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item name="token">
							<Input type="hidden" name="token" />
						</Form.Item>
						<Form.Item
							name="password"
							hasFeedback
							rules={[
								{
									required: true,
									pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
									message: 'Lütfen şifrenizi giriniz!',
								},
							]}
						>
							<Input.Password />
						</Form.Item>
						<Form.Item
							name="password2"
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Lütfen yukarıdaki şifrenizi giriniz!',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error('Şifreler eşleşmiyor!'));
									},
								}),
							]}
						>
							<Input.Password />
						</Form.Item>
						<Form.Item
							wrapperCol={{
								offset: 8,
								span: 16,
							}}
						>
							<Button loading={isLoading} type="primary" htmlType="submit" width={100}>
								Şifremi Oluştur
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</>
	);
};

export default CreatePassword;
