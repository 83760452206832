import styled from 'styled-components';

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: #f1f1f1;
	flex-direction: column;
`;

export { LoadingContainer };
